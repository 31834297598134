const Reducer = (state, action) => {
    switch (action.type) {
        case "UPDATE_USER_DATA":
            const user_data = { ...state.user_data, ...action.user_data };
            localStorage.setItem("user_data", JSON.stringify(user_data));
            return {
                ...state,
                user_data: user_data,
                roles: user_data.roles
                    ? user_data.roles
                    : ["subscription_manager"],
            };
        case "PRACTICE_ACCEPT":
            return {
                ...state,
                is_practice_accepted: !state.is_practice_accepted,
            };
        case "SUPPORT_ACCEPT":
            return {
                ...state,
                is_support_accepted: !state.is_support_accepted,
            };
        case "TOGGLE_MODAL_ACTIVE":
            return {
                ...state,
                is_modal_enabled: !state.is_modal_enabled,
            };
        case "SET_ROLES":
            return {
                ...state,
                roles: action.roles,
            };
        case "TOGGLE_MENU_ROUTER":
            return {
                ...state,
                menu_router: action.value,
            };
        case "UPDATE_MAIN_MENU_TYPE":
            return {
                ...state,
                main_menu_type: action.main_menu_type,
            };
        case "UPDATE_INNER_MENU_TYPE":
            return {
                ...state,
                inner_menu_type: action.inner_menu_type,
            };
        case "UPDATE_MAIN_MENU_REF":
            return {
                ...state,
                main_menu_ref: action.main_menu_ref,
            };
        case "UPDATE_INNER_MENU_REF":
            return {
                ...state,
                inner_menu_ref: action.inner_menu_ref,
            };
        case "SET_STUDENTS_MENU_ACTIVE":
            return {
                ...state,
                active_students_menu: action.value,
            };
        case "SET_MESSAGES":
            return {
                ...state,
                messages: action.messages,
            };
        case "UPDATE_CHAT_PROFILE":
            return {
                ...state,
                chat_profile: action.value,
            };
        case "UPDATE_PA_CHAT_PROFILE":
            return {
                ...state,
                pa_engineer_chat_profile: action.value,
            };
        case "ADD_MESSAGE":
            let messages = state.messages;
            if (action.message.message_type === "start_typing") {
                messages.forEach((item, index) => {
                    if (item.id === action.message.session_id) {
                        if (item.author !== state.chat_profile) {
                            messages[index].last_message = "typing...";
                        }
                    }
                });
            } else if (action.message.message_type === "start_recording") {
                messages.forEach((item, index) => {
                    if (item.id === action.message.session_id) {
                        if (item.author !== state.chat_profile) {
                            messages[index].last_message = "recording audio...";
                        }
                    }
                });
            } else if (action.message.message_type === "read_message") {
                messages.forEach((item, index) => {
                    if (item.id === action.message.session_id) {
                        if (action.message.author !== state.chat_profile) {
                            messages[index].unread_messages_count = 0;
                        }
                    }
                });
            } else {
                let message_item = messages.find((item) => {
                    return item.id === action.message.session_id;
                });
                messages = messages.filter((item) => {
                    return item.id !== action.message.session_id;
                });
                if (message_item) {
                    message_item.last_message = action.message.last_message;
                    message_item.message_time = action.message.message_time;
                    message_item.unread_messages_count =
                        action.message.rm_unread_messages_count;
                    messages.unshift(message_item);
                }
            }
            return {
                ...state,
                messages: messages,
            };
        default:
            return state;
    }
};

export default Reducer;
