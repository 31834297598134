const SupportEngineerReducer = (state, action) => {
    switch (action.type) {
        case "UPDATE_MODAL":
            return {
                ...state,
                modal_type: action.modal_type
                    ? action.modal_type
                    : state.modal_type,
                is_modal: action.is_modal,
            };
        case "UPDATE_PREMIUM_ASSIST_BIDS":
            return {
                ...state,
                premium_assist_bids: action.premium_assist_bids,
            };
        case "APPEND_TO_PREMIUM_ASSIST_BIDS":
            return {
                ...state,
                premium_assist_bids: [...state.premium_assist_bids, action.bid],
            };
        case "SELECT_BID":
            return {
                ...state,
                selected_bid: action.selected_bid,
            };
        case "UPDATE_ACTIVE_CHAT_SESSION":
            return {
                ...state,
                active_chat_session: action.active_chat_session,
            };
        case "UPDATE_ACTIVE_CHAT_SESSION_ID":
            return {
                ...state,
                active_chat_session_id: action.active_chat_session_id,
            };
        case "UPDATE_ACTIVE_PREMIUM_ASSIST":
            return {
                ...state,
                active_premium_assist: action.active_premium_assist,
            };
        case "UPDATE_PREVIOUS_PA_SESSIONS":
            return {
                ...state,
                previous_pa_sessions: action.previous_pa_sessions,
            };
        default:
            return state;
    }
};

export default SupportEngineerReducer;
