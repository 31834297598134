import React, { useState } from "react";
import "./App.css";
import "./assets/css/style.css";
import MainRouter from "./components/routing/routers/MainRouter";
import RouteLoading from "./components/routing/loadings/RouteLoading";
import Store from "./Store";
import CacheBuster from "./CacheBuster";

export const MenuActionContext = React.createContext();

function App() {
    const [isAppLoading, setAppLoading] = useState(false);

    return (
        <CacheBuster>
            {({ loading, isLatestVersion, refreshCacheAndReload }) => {
                if (loading) return null;
                if (!loading && !isLatestVersion) {
                    // You can decide how and when you want to force reload
                    refreshCacheAndReload();
                }

                return (
                    <>
                        <Store>
                            {isAppLoading ? <RouteLoading /> : <MainRouter />}
                        </Store>
                    </>
                );
            }}
        </CacheBuster>
    );
}

export default App;
